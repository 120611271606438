<script lang="ts">
  import type { ReadableAtom } from "nanostores";
  import { createEventDispatcher, onMount } from "svelte";
  import type { PointOfInterestFeature } from "../../data/types";
  import { filterOrigins, topOrigins } from "../../stores/origin-search-store";
  import type { UiTheme } from "../theme";
  import LocationSearch from "./LocationSearch.svelte";

  export let id: string;
  export let join: "right" | undefined = undefined;
  export let theme: UiTheme = "white";
  export let resetable: boolean = false;

  type EventMap = {
    pick: string;
    drop: void;
  };

  const dispatch = createEventDispatcher<EventMap>();

  let locations: ReadableAtom<PointOfInterestFeature[]>;

  onMount(() => {
    // prevent fetch during server render
    locations = topOrigins;
  });
</script>

<LocationSearch
  {id}
  {join}
  {theme}
  {resetable}
  locations={$locations ?? []}
  on:filter={(e) => filterOrigins(e.detail)}
  on:pick={(e) => dispatch("pick", e.detail)}
  on:drop={(e) => dispatch("drop")} />
