<script lang="ts">
  import * as features from "../../features";
  import {
      dropDestination,
      pickDestination,
      selectedDestination,
  } from "../../stores/destination-store";
  import { dropOrigin, pickOrigin, selectedOrigin } from "../../stores/origin-store";
  import Button from "../form/Button.svelte";
  import DestinationSearch from "../search-and-buy/SelectDestination.svelte";
  import SelectOrigin from "../search-and-buy/SelectOrigin.svelte";
  import type { UiTheme } from "../theme";

  export let id: string;
  export let theme: UiTheme = "gray";

  $: slug =
    $selectedDestination &&
    $selectedOrigin &&
    `/${$selectedDestination.properties.slug}/from/${$selectedOrigin.properties.slug}`;
</script>

<form
  class="flex flex-col gap-6 text-base font-light"
  method="get"
  action={slug}>
  <div>
    <label for="{id}-dest" class="text-sm">Travel to</label>
    <DestinationSearch
      id="{id}-dest"
      {theme}
      resetable={!$selectedOrigin}
      on:pick={(e) => pickDestination(e.detail)}
      on:drop={() => {
        dropOrigin();
        dropDestination();
      }} />
  </div>

  <div>
    <label for="{id}-origin" class="text-sm">From</label>
    <SelectOrigin
      id="{id}-origin"
      {theme}
      resetable
      on:pick={(e) => pickOrigin(e.detail)}
      on:drop={() => dropOrigin()} />
  </div>

  {#if features.multiLeg}
    <div>
      <a href="#" class="text-sm text-base-300">&plus; Add leg</a>
    </div>
  {/if}
  {#if !features.multiLeg}
    <hr />
  {/if}

  <div>
    <Button type="submit" theme="secondary" shadow block disabled={!slug}>Find travel options</Button>
  </div>
</form>
